@import "../new_lander/breakpoint_mixin.scss";

$seperator: rgba(197, 209, 219, 0.2);

#footer {



    .wrapper {

        @include breakpoint-w(975px) {

            padding: 0 20px;
        }
    }

    .wrapper .row nav {

        display: -ms-flexbox;
        display: flex;


        a {

            font-size: 15px;
            line-height: 15px;
            color: #fff;


        }
    }

    .break {
        display: none
    }

        @include breakpoint-w(975px) {


        .break {
            background: $seperator;
            height: 1px;
            width: 100%;
            margin: 14px 0px;
            display: block;


        }
    }

    a {
        font-family: 'Lato';
        color: #E1E9F0;

        &:hover {
            color: #fff;
        }

        @include breakpoint-w(975px) {

            font-size: 16px;
        }
    }

    font-family: 'Lato', sans-serif;
    font-size: 15px;
    // padding: 20px 0;


    .row {
        display: flex ;
        align-items: center ;
        justify-content: space-between ;
        flex-direction: row ;

        max-width: 1140px ;
        width: 100% ;
        margin: 0 auto;

        padding: 20px 30px;

        @include breakpoint-w(975px) {
            flex-direction: column;
            padding: 10px 30px;
            align-items: flex-start;

            .logo img {
                margin-bottom: 10px;
            }
        }

        // @include breakpoint-w(975px) {

        //     .logo {
        //         width: 230px;
        //     }

        //     justify-content: space-evenly;
        // }

        img {
            order: unset;
            margin-bottom: 0px;
        }

    }

    .questions {
        flex-grow: 1;
        margin-left: 20px;
    }

    .contacts {
        margin-right: 20px;
    }

    .contacts:last-of-type {
        margin-right: 0;
    }

    @include breakpoint-w(975px) {


        .questions,
        .logo {
            margin: 0 auto;
        }
    }


    .contacts {
        span {
            @include breakpoint-w(975px) {

                display: flex;
                flex-direction: row;
                justify-content: center;
                // align-items: center;
            }

            img {
                margin-right: 8px;
            }
        }
    }

    .footer-top {
        background: #000;
        color: #fff;
        max-width: 100%;
        padding: 0 20px;

        .desktop {
            display: flex;
        }

        @include breakpoint-w(975px) {

            .desktop {
                display: none;
            }
        }
    }

    .logo {
        width: 230px;
    }

    .contacts {
        display: flex;
        line-height: 18px;

        span {
            display: flex;
            align-content: center;
            justify-content: center;

            @include min-tablet {
                flex-direction: row;
            }

            @include breakpoint-w(975px) {

                font-size: 15px;
                line-height: 29px;
                margin-bottom: 4px;
            }

        }



        img {
            width: 18px;
            height: 18px;

            @include breakpoint-w(975px) {

                width: 26px;
                height: 26px;
                font-size: 18px;
                top: 3px;
                position: relative;
            }

        }

    }

    .logo-row {
        padding: 15px 0px 10px;

        @include breakpoint-w(975px) {

            padding-bottom: 0;
        }
    }

    .desktop {
        display: block;
    }

    .mobile {
        display: none;

        .row {
            flex-direction: row;
            padding: 0;
            padding-bottom: 10px;

            a {
                display: block;
                padding: 0;
                border: 0;
                margin: 0px 5px 15px;
            }
        }

        .col {
            width: 50%;
        }

        .col:last-of-type {
            border-left: 1px solid $seperator;
            padding-left: 25px;
        }
    }


    @include breakpoint-w(975px) {

        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    }

    .menu {

        .row {
            flex-direction: row;
            padding: 0;
            padding-bottom: 10px;
        }

        .col:last-of-type {
            border-left: 1px solid $seperator;
            padding-left: 25px;
        }

        border-top: 1px solid $seperator;
        padding: 10px 0px;

        @include breakpoint-w(975px) {

            border-top: 0;
            padding: 0;
        }


        nav {
            width: 100%;
            display: flex;
            height: 30px;
            justify-content: space-between;
            align-items: center;


            @include breakpoint-w(975px) {

                height: auto;
                text-align: left;


                .break {
                    display: block
                }
            }

            @include breakpoint-w(975px) {

                flex-direction: column;
                // padding: 10px 0px;
                height: 100%;
                align-items: flex-start;
            }

            a {
                border-right: 1px solid $seperator;
                padding: 0 17px;
                transition: color .3s;

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0;
                }

                @include breakpoint-w(975px) {

                    display: block;
                    border-right: 0 !important;
                    margin: 0px 5px 15px;
                    padding-left: 0;
                }
            }


            text-align: center;
        }

    }

    .row-copyright {
        padding: 20px 0;
        

        @include breakpoint-w(1200px) {
            padding: 20px 0 20px;
        }

        @include breakpoint-w(767px) {
            padding: 20px 0 20px;
        }

        .payments img {
            width: 100%;
        }

        .copyrights {
            font-family: Lato, sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 15px;
            text-align: center;
            color: #333;
        }



        @include breakpoint-w(975px) {

            align-items: center;
            flex-direction: column-reverse;

            .payments img {
                margin-top: 10px;
            }
        }
    }

}