@mixin tablet {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin min-tablet {
  @media screen and (min-width: 1201px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobile-xs {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin breakpoint-w($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin breakpoint-h($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

@mixin breakpoint-both($height, $width) {
  @media screen and (max-height: $height) and (max-width: $width) {
    @content;
  }
}

@mixin smartphones {
  @media screen and (max-width: 375px) {
    @content;
  }
}